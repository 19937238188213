Array.prototype.selfMap = function <T>(this: T[]): Record<IndexType<T>, T> {
  return this.reduce(
    (obj, val) => {
      if (
        typeof val === "string" ||
        typeof val === "number" ||
        typeof val === "symbol"
      ) {
        obj[val as IndexType<T>] = val;
      }
      return obj;
    },
    {} as Record<IndexType<T>, T>
  );
};

Date.prototype.addDays = function (days) {
  this.setUTCDate(this.getUTCDate() + days);
  return this;
};

Date.prototype.addYears = function (years) {
  this.setUTCFullYear(this.getUTCFullYear() + years);
  return this;
};

Date.prototype.toISODateString = function () {
  return this.toISOString().split("T")[0] ?? "";
};

Date.prototype.toUTCDate = function () {
  return new Date(
    Date.UTC(this.getUTCFullYear(), this.getUTCMonth(), this.getUTCDate())
  );
};

HTMLElement.prototype.setInnerHeight = function (height: number) {
  const computedStyle = window.getComputedStyle(this);
  const paddingTop = parseInt(computedStyle.paddingTop);
  const paddingBottom = parseInt(computedStyle.paddingBottom);
  this.style.height = `${
    height -
    (isNaN(paddingTop) ? 0 : paddingTop) -
    (isNaN(paddingBottom) ? 0 : paddingBottom)
  }px`;
};

HTMLElement.prototype.setInnerWidth = function (width: number) {
  const computedStyle = window.getComputedStyle(this);
  const paddingLeft = parseInt(computedStyle.paddingLeft);
  const paddingRight = parseInt(computedStyle.paddingRight);
  this.style.width = `${
    width -
    (isNaN(paddingLeft) ? 0 : paddingLeft) -
    (isNaN(paddingRight) ? 0 : paddingRight)
  }px`;
};

Number.prototype.clamp = function (min, max) {
  return Math.max(Math.min(this.valueOf(), max), min);
};

Number.prototype.roundTo = function (decimalPlaces) {
  const factor = 10 ** decimalPlaces;
  return Math.round(this.valueOf() * factor) / factor;
};
